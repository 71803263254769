import React from "react";
import Image from "gatsby-image";
import { Link } from "gatsby";
import SEO from "../components/seo";

/* Artists Page
============================================================================= */

const ArtistsPage = ({ data }) => {
	// Config
	const { allSanityArtist } = data;
	const { nodes: artists } = allSanityArtist;

	// Render Alphabet
	const renderAlphabet = () => {
		const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ#";
		return alphabet.split("").map((letter, i) => {
			return (
				<a
					href={`#${letter}`}
					className="transition transition-all hover:text-orange cursor-pointer pl-3"
				>
					{letter}
				</a>
			);
		});
	};

	// Render Artists
	const renderArtists = () => {
		return artists.map((artist, i) => {
			try {
				return <ArtistGridItem artist={artist} key={i} />;
			} catch (error) {
				return null;
			}
		});
	};

	// Render
	return (
		<>
			<SEO title="Artists" />
			<div className="w-full bg-white">
				<div className="px-10 py-10 flex items-center justify-between">
					<h2 className="text-darkblue ibm-plex text-5xl font-sans font-black ">
						ARTISTS
					</h2>
					<div className="lg:flex items-center justify-end ibm-plex font-bold text-xl text-darkblue hidden">
						{renderAlphabet()}
					</div>
				</div>
				<div className="w-full grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
					{renderArtists()}
				</div>
			</div>
		</>
	);
};

/* Artist Grid Item
============================================================================= */

const ArtistGridItem = ({ artist }) => {
	try {
		// Config
		const { fluid } = artist.image.asset;
		// Render
		return (
			<Link to={`/artists/${artist.slug}`}>
				<div className="w-full relative">
					<Image
						className="w-full relative h-0 transition transition-transform translate-x-0 translate-y-0 hover:translate-x-5 hover:translate-y-5 hover:scale-125"
						style={{ paddingBottom: "100%" }}
						fluid={fluid}
						imgStyle={{ objectFit: "cover" }}
					/>
					<div className="bg-darkblue w-full flex items-center justify-center absolute top-0 left-0 h-full transition duration-500 transition-opacity opacity-0 hover:opacity-60">
						<h2 className="ibm-condensed text-white text-4xl font-bold text-center transition transition-transform transform duration-500 translate-y-0 hover:translate-y-0">
							{artist.name.toUpperCase()}
						</h2>
					</div>
				</div>
			</Link>
		);
	} catch (error) {
		return null;
	}
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	query ArtistsPageQuery {
		allSanityArtist {
			nodes {
				name
				slug
				image {
					asset {
						fluid {
							src
							srcSetWebp
							srcWebp
							srcSet
							base64
						}
					}
				}
			}
		}
	}
`;

/* Export
============================================================================= */

export default ArtistsPage;
